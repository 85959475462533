export default {
  footer: {
    footerLinks: {
      privacyPolicy: {
        url: '/Merged_docs/privacy_policy/RPA_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/RPA_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url: '/Merged_docs/relationship_summary/RPA_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url: '/Merged_docs/conversation_starters/RPA_DMP_conversation_starters_merge.pdf'
      }
    }
  }
};
