export default {
  advisorFirmInfo: {
    advisorFirmName: 'Global Retirement Partners, LLC'
  },
  footer: {
    footerLinks: {
      advisoryAgreement: {
        title: 'INVESTMENT ADVISORY AGREEMENT'
      },
      privacyPolicy: {
        url: '/Merged_docs/privacy_policy/GRP_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/GRP_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url: '/Merged_docs/relationship_summary/GRP_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url: '/Merged_docs/conversation_starters/GRP_DMP_conversation_starters_merge.pdf'
      }
    }
  }
};
