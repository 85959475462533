import simpleStore from '@/simpleStore';
export default {
  getProgramFeeVal: (state) => state.programFee,
  getHeroText: (state) => state.config.heroText,
  getShowLoader: (state) => state.showLoader,
  getSavingRecommendationShowLoader: (state) =>
    state.savingRecommendation.showLoader,
  getSavingRateDetail: (state) => state.savingRecommendation.savingRateDetail,
  getIsEnrollButtonDisable: (state) => state.isEnrollButtonDisable,
  getProposalAdvice: (state) => state.proposalAdvice,
  getAccountDetails: (state) => {
    const accountIds = [];
    const accountDetails = [];
    const currentAccounts = state.proposalAdvice.CurrentAdviceStrategy;
    const proposedAccounts = state.proposalAdvice.ProposedAdviceStrategy;
    const plans = simpleStore.getPlanInfo();
    currentAccounts?.AccountSavingsRateDetail.forEach((acc) => {
      if (plans.find((plan) => plan.AccountId === acc.Account)) {
        accountIds.push(acc.Account);
      }
    });
    for (let i = 0; i < accountIds.length; i++) {
      let currentStrategy = currentAccounts?.AccountSavingsRateDetail.find(
        (account) => account.Account == accountIds[i]
      );
      let proposedStrategy = proposedAccounts?.AccountSavingsRateDetail.find(
        (account) => account.Account == accountIds[i]
      );
      let { PlanName, PlanType, PlanTypeLabel, PlanBalance } = plans.find(
        (plan) => plan.AccountId == accountIds[i]
      );
      accountDetails[i] = {
        id: i,
        account: accountIds[i],
        currentAdviceStrategy: {
          strategy: currentStrategy,
          assetMixAdvisableAccounts:
            currentAccounts.AssetMixAdvisableAccounts[accountIds[i]],
          riskLevel:
            currentAccounts.AssetMixAdvisableAccounts[accountIds[i]].RiskLevel,
          savingsPercentage: currentStrategy.SavingsComponent.reduce(function (
            total,
            arr
          ) {
            const result = parseFloat(total + arr.Value);
            return result % 1 !== 0 ? parseFloat(result.toFixed(2)) : result;
          },
          0)
        },
        proposedAdviceStrategy: {
          strategy: proposedStrategy,
          assetMixAdvisableAccounts:
            proposedAccounts.AssetMixAdvisableAccounts[accountIds[i]],
          riskLevel:
            proposedAccounts.AssetMixAdvisableAccounts[accountIds[i]].RiskLevel,
          savingsPercentage: proposedStrategy.SavingsComponent.reduce(function (
            total,
            arr
          ) {
            const result = parseFloat(total + arr.Value);
            return result % 1 !== 0 ? parseFloat(result.toFixed(2)) : result;
          },
          0)
        },
        planInfo: {
          name: PlanName,
          type: PlanType,
          balance: Math.round(PlanBalance),
          label: PlanTypeLabel
        }
      };
    }
    return accountDetails;
  },
  getAges: (state) => JSON.parse(state.ages),
  getIsIncomeProjectionDataAvailable: (state) => {
    return state.incomeProjectionDataAvailable;
  },
  getRecaptchaV2: (state) => state.recaptchaV2,
  getShowInggVraUX: (state) => state.showInggVraUX,
  getClientCustomizations: (state) => state.clientCustomizations
};
