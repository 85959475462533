export default {
  advisorFirmInfo: {
    advisorFirmName: 'Soltis Investment Advisors, LLC'
  },
  footer: {
    footerLinks: {
      advisoryAgreement: {
        title: 'Soltis Investment Advisors, LLC. Advisory Agreement',
        url: 'https://work-rm-compliance-docs.s3.amazonaws.com/Advisory+Agreement_Soltis.pdf'
      },
      privacyPolicy: {
        url: '/Merged_docs/privacy_policy/SOLTIS_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/SOLTIS_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url: '/Merged_docs/relationship_summary/SOLTIS_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url: '/Merged_docs/conversation_starters/SOLTIS_DMP_conversation_starters_merge.pdf'
      }
    }
  }
};
