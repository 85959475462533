export function createIdleActivityMonitor(idleTimeInSeconds, onIdle) {
  let timeoutId;
  let isModalShown = false;

  const resetTimer = () => {
    // Prevent further timer restarts after modal is shown
    if (isModalShown) return;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onIdle();
      // Stop the timer after showing the modal
      isModalShown = true;
    }, idleTimeInSeconds * 1000);
  };

  const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

  const startTimer = () => {
    events.forEach((event) => window.addEventListener(event, resetTimer));
    // Initialize the first timer
    resetTimer();
  };

  const stopTimer = () => {
    events.forEach((event) => window.removeEventListener(event, resetTimer));
    clearTimeout(timeoutId);
  };

  return { startTimer, stopTimer };
}
