<template>
  <div class="footer">
    <div class="container">
      <div class="footer-text" data-ee-test="footer-text">
        <div v-html="footerDisclaimer"></div>
        <ProjectionDisclosure v-if="getRouteName === 'advice'" show-full-text />
        <img
          alt="Morningstar Retirement Logo"
          class="padding-top"
          src="@/assets/images/logos/morningstar-retirement-logo.svg"
        />
      </div>
      <div class="footer-links">
        <mds-list-group class="footer-links--list">
          <Fragment v-for="(link, index) in getFooterLinks" :key="index">
            <mds-list-group-item
              v-if="link.type === 'link' && link.show"
              :text="link.name"
              :href="getDocumentLink(link.url)"
              :data-ee-test="`footer-links-${index}`"
            />
            <mds-list-group-item
              v-if="link.type === 'modal' && link.show"
              :text="link.name"
              @mds-list-item-clicked="getContent(link.modal)"
              :data-ee-test="`footer-links-${index}`"
            />
          </Fragment>
        </mds-list-group>
      </div>
    </div>
    <ClientContentModal
      v-if="showModal"
      @footer-modal-dismissed="showModal = $event"
      :model="showModal"
      :content="modalData"
    />
  </div>
</template>

<script>
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import ClientContentModal from '../footer-modals/ClientContentModal';
import { Fragment } from 'vue-fragment';
import footerModalData from '../../mixins/footer-modal-data';
import { mapActions } from 'vuex';
import { calculateProgramFees } from '@/mappers/programFee';
import store from '@/simpleStore';
import { isValidDate, toUSDate } from '@/shared/utils/date';
import ProjectionDisclosure from '../../Advice/ProjectionDisclosure.vue';

export default {
  components: {
    MdsListGroup,
    MdsListGroupItem,
    Fragment,
    ClientContentModal,
    ProjectionDisclosure
  },
  mixins: [footerModalData],
  props: {
    hasSpecialOffer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    footerDisclaimer() {
      const footerDisclaimer = this.$contentTransposer('footer.disclaimers', {
        year: this.getYear,
        disclaimerSpecialOffer: this.getSpecialOfferText()
      });
      const advisorFirmName = this.$contentTransposer(
        'advisorFirmInfo.advisorFirmName'
      );
      const disclaimersOfferedBy = this.$contentTransposer(
        'footer.disclaimersOfferedBy',
        { advisorFirmName, year: this.getYear }
      );

      return footerDisclaimer + disclaimersOfferedBy;
    }
  },
  created() {
    const programFee = store.getProgramFees() ? calculateProgramFees() : null;
    this.setProgramFeeStatement(programFee);
  },
  mounted() {
    const mdsListLinks = document.querySelectorAll(
      'a.mds-list-group__link__easy-enroll'
    );
    mdsListLinks.forEach((ele) => {
      ele.setAttribute('target', '_blank');
    });
  },
  methods: {
    ...mapActions({
      setProgramFeeStatement: 'setProgramFeeStatement',
      setFooterLinksContent: 'setFooterLinksContent'
    }),
    getSpecialOfferText() {
      const { offerExpirationDate } = store.getEligibleInformation();

      if (!this.hasSpecialOffer || !offerExpirationDate) {
        return '';
      }

      const convertedOfferDate = new Date(offerExpirationDate);
      const isValidOffer = isValidDate(convertedOfferDate);

      if (!isValidOffer) {
        return '';
      }

      const dynamicExpiryDate = toUSDate(convertedOfferDate, {
        month: 'long',
        day: 'numeric'
      });

      return this.$contentTransposer('footer.disclaimerSpecialOffer', {
        dynamicExpiryDate
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/footer.scss';
</style>
