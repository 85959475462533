export const newrelicSetAttribute = (attributesList) => {
  if (typeof window.newrelic !== 'object') {
    return;
  }

  let interaction = window.newrelic.interaction();

  attributesList.forEach((element) => {
    interaction = interaction.setAttribute(element.key, element.value);
  });

  interaction.save();
};

/**
 * The function helps add a custom page action to newrelic.
 * For Example if you need to track users who have added a particular account from UI
 * Or performed a particular action.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-page-action/
 * @param {String} actionName Name or category of the action
 * @param {Object} payload Object with one or more key/value pairs.
 */
export const setNewrelicCustomPageAction = (actionName, payload = {}) => {
  if (typeof window.newrelic === 'object') {
    window.newrelic.addPageAction(actionName, payload);
  }
};
