<template>
  <EeContentWrapper has-special-offer>
    <confirm-enrollment :isSameSavingsRate="model.isSameSavingsRate" />
    <account-details
      v-if="
        !model.enableContributionChanges && !model.isSameSavingsRate && isActive
      "
      :isSameSavingsRate="model.isSameSavingsRate"
    />
    <about-morningstar />
  </EeContentWrapper>
</template>
<script>
import EeContentWrapper from '../layout/ee-content-wrapper';
import ConfirmEnrollment from './ConfirmEnrollment';
import AboutMorningstar from '../AboutMorningstar';
import AccountDetails from './AccountDetails';
import store from '@/simpleStore';
import footerModalData from '../mixins/footer-modal-data';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import { mapActions } from 'vuex';

export default {
  components: {
    EeContentWrapper,
    ConfirmEnrollment,
    AboutMorningstar,
    AccountDetails
  },
  mixins: [footerModalData],
  data() {
    return {
      model: {
        enableContributionChanges: store.getContributionChangesFlag(),
        currentSavingsRateDetail: null,
        proposedSavingsRateDetail: null,
        isSameSavingsRate: false
      },
      pageContentParams: {
        isAMA: store.isAMA(),
        advisoryFirmId: null,
        clientId: null
      }
    };
  },
  computed: {
    currentAdviceStrategy() {
      return store.getProposalAdvice().CurrentAdviceStrategy;
    },
    proposedAdviceStrategy() {
      return store.getProposalAdvice().ProposedAdviceStrategy;
    },
    isActive() {
      const accounts = store.getAccounts();
      return accounts.some((account) => account.Status === 'Active');
    }
  },
  created() {
    const proposalAdvice = store.getProposalAdvice();
    this.setProposalAdvice(proposalAdvice);
    this.setIncomeProjection(proposalAdvice);
    localStorage.setItem('showDefaultLandingLinks', false);
    this.model.currentSavingsRateDetail =
      this.currentAdviceStrategy.SavingsRateDetail;
    this.model.proposedSavingsRateDetail =
      this.proposedAdviceStrategy.SavingsRateDetail;
    this.model.isSameSavingsRate = this.isSameSavingsRate();
    this.setHeroTitle();
  },
  mounted() {
    newRelicTracking();
  },
  methods: {
    ...mapActions({
      setHeroText: 'setHeroText',
      setProposalAdvice: 'setProposalAdvice',
      setIncomeProjection: 'setIncomeProjection'
    }),
    /* Checking if SavingsComponent value and type is same or not from current and proposed advice strategy 
    /* of single and integrated accounts. If value is different then contribution message will be shown */
    isSameSavingsRate() {
      return this.model.currentSavingsRateDetail.every((currItem, id) => {
        return currItem.SavingsComponent.every((currentSavingsItem, idx) => {
          return (
            currentSavingsItem.Type ===
              this.model.proposedSavingsRateDetail[id].SavingsComponent[idx]
                .Type &&
            currentSavingsItem.Value ===
              this.model.proposedSavingsRateDetail[id].SavingsComponent[idx]
                .Value
          );
        });
      });
    },
    setHeroTitle() {
      const heroText = this.$contentTransposer('hero.title');

      this.setHeroText(heroText);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/enrollment.scss';
</style>
