<template>
  <div :style="[getRecaptchaV2 ? {} : { display: 'none' }]" class="g-recaptcha">
    <mds-inline-message variation="informational" tinted class="captcha-msg">
      Click the 'I am not a robot' checkbox below and then click Get Advice
    </mds-inline-message>
    <div
      :id="recaptchaId"
      :data-sitekey="sitekey"
      :data-action="actionName"
    ></div>
  </div>
</template>

<script>
import MdsInlineMessage from '@mds/inline-message';
const reCaptchaSiteKeyV3 = {
  nonProd: '6LeifKcZAAAAAHzPpmtqUwmItqppifu-wtG6JFUD',
  prod: '6Lfbe6cZAAAAAIrEQTzlqbJyZU9f95BqrGglwxqv'
};

const reCaptchaSiteKeyV2 = {
  nonProd: '6Ld9nX4nAAAAAF394HRiiaFM9kbBYYEknMN1vovZ',
  prod: '6LfutH4nAAAAAE8IzfhBjwRfxTDGigqMflG9GW3A'
};

export default {
  components: {
    MdsInlineMessage
  },
  props: {
    actionName: {
      type: String,
      required: true,
      default: null
    },
    getRecaptchaV2: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    const isNonProd =
      window.location?.origin?.toLowerCase()?.includes('-qa.') ||
      window.location?.origin?.toLowerCase()?.includes('-uat.') ||
      window.location?.origin?.includes('//localhost:');
    return {
      sitekey: isNonProd
        ? this.getRecaptchaV2
          ? reCaptchaSiteKeyV2.nonProd
          : reCaptchaSiteKeyV3.nonProd
        : this.getRecaptchaV2
        ? reCaptchaSiteKeyV2.prod
        : reCaptchaSiteKeyV3.prod,
      recaptchaId: 'g-recaptcha',
      widgetId: 0,
      version: this.getRecaptchaV2 ? 'v2' : 'v3'
    };
  },
  mounted() {
    if (!window.grecaptcha) {
      const script = document.createElement('script');
      script.src =
        this.version === 'v2'
          ? 'https://www.google.com/recaptcha/api.js'
          : 'https://www.google.com/recaptcha/api.js?render=explicit';
      script.onload = this.version === 'v3' ? this.renderWait : undefined;
      script.async = this.version === 'v3' ? true : undefined;
      script.defer = this.version === 'v3' ? true : undefined;
      document.head.appendChild(script);
    } else {
      this.render();
    }
  },
  methods: {
    async execute() {
      const response =
        this.version === 'v3' ? await this.executeV3() : this.executeV2();
      this.$emit('verify', response);
    },
    reset() {
      window.grecaptcha.reset(this.widgetId);
    },
    executeV2() {
      const response = window.grecaptcha.getResponse();
      this.reset();
      return response;
    },
    async executeV3() {
      const response = await window.grecaptcha.execute(this.widgetId);
      this.reset();
      return response;
    },
    renderWait: function () {
      setTimeout(() => {
        window.grecaptcha && typeof window.grecaptcha.render === 'function'
          ? this.render()
          : this.renderWait();
      }, 200);
    },
    render() {
      if (window.grecaptcha && window.grecaptcha.render) {
        this.widgetId = window.grecaptcha.render(this.recaptchaId, {
          sitekey: this.sitekey,
          callback: (response) => {
            this.$emit('verify', response);
            this.reset();
          },
          ...(this.version === 'v3' && {
            size: 'invisible'
          })
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/g-recaptcha.scss';
</style>
