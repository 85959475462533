<template>
  <div id="app">
    <router-view></router-view>
    <SessionTimeoutWarning v-if="showSessionTimeoutWarning" />
  </div>
</template>
<script>
import store from '@/simpleStore';
import { mapActions } from 'vuex';
import { toUpper } from 'lodash';
import SessionTimeoutWarning from '@/components/SessionExpired/SessionTimeoutWarning';
import { getENVInfo } from './config-customization/config-init';
import { mergeClientCustomizations } from './client-customizations';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    SessionTimeoutWarning
  },
  data() {
    return {
      env: null
    };
  },
  computed: {
    showSessionTimeoutWarning() {
      return this.$route.name !== 'landing';
    }
  },
  created() {
    this.env = getENVInfo();
    this.initializeState();
  },
  mounted() {
    window.onpopstate = function () {
      history.go(1);
    };
  },
  methods: {
    ...mapGetters({
      getClientCustomizations: 'getClientCustomizations'
    }),
    ...mapActions({
      initializeVueState: 'initializeVueState',
      initializeClientCustomizations: 'initializeClientCustomizations'
    }),
    initializeState() {
      const { cl, ra, code, vr } = this.$route.query;

      if (this.$route.query) {
        store.setQueryParams({
          customizationId: toUpper(cl),
          advisoryFirmId: toUpper(ra),
          entryCode: code,
          version: toUpper(vr)
        });
        if (code) {
          sessionStorage.setItem('URLEntryCode', code);
        }
      }
      const version = toUpper(vr);
      const clientId = store.getClientId() ?? toUpper(cl);
      const initializeStateModel = {
        isAMA: store.isAMA(),
        advisoryFirmId: store.getAdvisoryFirmId() ?? toUpper(ra),
        clientId,
        env: this.env,
        version
      };
      // This will set content in vuex store
      this.initializeVueState(initializeStateModel);
      this.initializeClientCustomizations(mergeClientCustomizations());

      // Add clientId and Version to body class for client specific CSS customization
      if (clientId) {
        const clientCSSOverrideClass = version
          ? `${clientId.toLowerCase()}--${version.toLowerCase()}`
          : clientId.toLowerCase();
        document.body.classList.add(clientCSSOverrideClass);
      }
    }
  }
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import '@/assets/css/client/default/global';
@import '@/assets/css/client/ingg/global';
</style>
