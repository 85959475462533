import store from '@/simpleStore';
import { ADVISORY_AGREEMENT, CONTACT_US } from '../../constants';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import { UI_BASE_URL_QA } from '@/constants';

export default {
  data() {
    return {
      showModal: false,
      modalData: {
        name: null,
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapGetters({
      getProgramFeeVal: 'getProgramFeeVal'
    }),
    getIsAMA() {
      return store.isAMA();
    },
    getFooterLinks() {
      const footerLinks = store.getDefaultLandingLinks()
        ? this.$contentTransposer('landing.footerLinks')
        : cloneDeep(this.$contentTransposer('footer.footerLinks'));
      Object.keys(footerLinks)
        .filter((footerLink) => footerLinks[footerLink].type === 'modal')
        .forEach((footerLink) =>
          this.showSupportAndAdvisoryAgreementModal(footerLinks[footerLink])
        );
      return footerLinks;
    },
    getRouteName() {
      return this.$route.name;
    },
    getModalContent() {
      let content =
        this.modalData.name === 'advisoryAgreement'
          ? this.$contentTransposer(`${this.modalData.name}`, {
              planFeeText: this.getProgramFeeVal
            })
          : this.$contentTransposer(`${this.modalData.name}`);
      const tempDivElement = document.createElement('div');
      tempDivElement.innerHTML = content;
      const imageElements = tempDivElement.querySelectorAll('img');
      if (imageElements.length) {
        imageElements.forEach((img) => {
          const imgName = img.src.split('/').pop();
          img.src = require(`../../assets/images/${imgName}`);
        });
      }
      content = tempDivElement.innerHTML;
      return content;
    },
    getYear() {
      return new Date().getUTCFullYear();
    }
  },
  methods: {
    async getContent(modalName) {
      this.modalData.name = modalName;
      this.modalData.title = this.$contentTransposer(
        `footer.footerLinks.${modalName}.title`
      );
      this.modalData.body = this.getModalContent;
      this.showModal = true;
    },
    showSupportAndAdvisoryAgreementModal(footerLink) {
      const footerLinksOnLanding = [ADVISORY_AGREEMENT, CONTACT_US];
      if (
        this.getRouteName === 'landing' &&
        footerLinksOnLanding.includes(footerLink.modal)
      ) {
        footerLink.show = false;
      }
    },
    getDocumentLink(url) {
      return url.includes('Merged_docs') &&
        process.env.NODE_ENV === 'development'
        ? `${UI_BASE_URL_QA}${url}`
        : url;
    }
  }
};
