export const toUSDate = (date, options = {}) => {
  return date.toLocaleString('en-US', {
    year: options.year || 'numeric',
    month: options.month || '2-digit',
    day: options.day || '2-digit'
  });
};

export const isValidDate = (date) => {
  const currentDate = new Date();

  if (date.getTime() > currentDate.getTime()) {
    return true;
  }

  // Check if date is today, otherwise, is not valid date
  return toUSDate(date) === toUSDate(currentDate);
};
