import DonutChart from 'mbc-donut';
const eeDonutChart = DonutChart.extend({
  afterDraw() {
    this.__super__.draw.call(this);
    this.addPercentageSymbol();
  },
  addPercentageSymbol() {
    const legenedValueElements =
      document.getElementsByClassName('legend-value');
    legenedValueElements.forEach((el) => {
      if (el.textContent.indexOf('%') <= 0) {
        el.textContent += '%';
      }
    });
  }
});
export default eeDonutChart;
