<template>
  <div class="verify-eligibility-form">
    <h3 class="ee__section-heading">
      {{ $contentTransposer('landing.verifyForm.header') }}
    </h3>
    <MdsForm size="large">
      <MdsFieldset legend="Eligibility Form" hidden-legend>
        <h5
          v-html="
            $contentTransposer('landing.verifyForm.subHeader', {
              productName: getProductName
            })
          "
          class="sub-heading"
        />
        <MdsRequiredFieldKey
          :text="$contentTransposer('landing.verifyForm.requiredField')"
        />
        <MdsInput
          v-for="field in getFields.filter((i) => i.fieldType === 'text')"
          :id="field.id"
          :key="field.id"
          v-model="field.value"
          :placeholder="field.placeholder"
          :label="field.label"
          :microcopy-above="field.microcopyTxt"
          :error-text="[`${field.errorMsg}`]"
          :error="field.errorStatus"
          :data-ee-test="`eligibility-form-${field.id}`"
          :disabled="getFieldDisableStatus(field.id)"
          :maxlength="field.maxlength"
          @input="checkIfEmpty(field.id)"
          @keydown.space.prevent
          size="large"
          required
        />
        <MdsDatePicker
          v-for="field in getFields.filter((i) => i.fieldType === 'date')"
          :min-max-dates="field.minMaxDates"
          :id="field.id"
          :key="field.id"
          :value="field.value"
          :placeholder="field.placeholder"
          :label="field.label"
          :error-text="[`${field.errorMsg}`]"
          :error="field.errorStatus"
          :data-ee-test="`eligibility-form-${field.id}`"
          :disabled="getFieldDisableStatus(field.id)"
          :maxlength="field.maxlength"
          :typing="true"
          :date-format="{
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          }"
          @keydown.space.prevent
          @input="(value) => handleInputChangedEvent(value, field.id)"
          size="large"
          required
        />
      </MdsFieldset>
      <div>
        <GRecaptcha
          ref="GRecaptcha"
          :getRecaptchaV2="getRecaptchaV2"
          @verify="onSubmit"
          class="gRecaptcha"
          action-name="ProposalVerify"
        ></GRecaptcha>
      </div>
      <MdsButton
        :disabled="getHasEmptyInputFields"
        @click="executeRecaptcha"
        class="verify-btn"
        variation="primary"
        size="touch"
        data-ee-test="eligibility-form-button"
        type="button"
      >
        {{ $contentTransposer('button.getAdvice') }}
      </MdsButton>
    </MdsForm>
    <p class="link-txt">
      <span>{{
        $contentTransposer('landing.verifyForm.privacyPolicy.text')
      }}</span>
      <a
        v-if="getIsAMA"
        :href="
          getDocumentLink(
            $contentTransposer('footer.footerLinks.privacyPolicy.url')
          )
        "
        target="_blank"
        class="ee-link"
        rel="noopener noreferrer"
      >
        {{ $contentTransposer('landing.verifyForm.privacyPolicy.link') }}.</a
      >
      <a
        v-if="!getIsAMA && getVersion"
        :href="$contentTransposer('landing.verifyForm.privacyPolicy.url')"
        target="_blank"
        class="ee-link"
        rel="noopener noreferrer"
      >
        {{ $contentTransposer('landing.verifyForm.privacyPolicy.link') }}.</a
      >
      <span
        v-if="!getIsAMA && !getVersion"
        @click="getContent(PRIVACY_POLICY)"
        class="ee-link"
      >
        {{ $contentTransposer('landing.verifyForm.privacyPolicy.link') }}.</span
      >
    </p>
    <ClientContentModal
      v-if="showModal"
      @footer-modal-dismissed="showModal = $event"
      :model="showModal"
      :content="modalData"
    />
  </div>
</template>

<script>
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import MdsFieldset from '@mds/fieldset';
import { MdsButton } from '@mds/button';
import MdsRequiredFieldKey from '@mds/required-field-key';
import { validate, getErrorMessage, getEnrollmentData } from '../../utils';
import {
  LAST_NAME,
  ENTRY_CODE,
  DATE_OF_BIRTH,
  PRIVACY_POLICY,
  BLOCK_CLIENTS,
  VERIFY_PATH,
  PAGE_ID,
  LOG_CLIENT_ERROR
} from '../../../constants';
import store from '@/simpleStore';
import footerModalData from '../../mixins/footer-modal-data';
import ClientContentModal from '../../layout/footer-modals/ClientContentModal';
import GRecaptcha from './G-Recaptcha.vue';
import { mapActions, mapGetters } from 'vuex';
import apiService from '../../service/api-service';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import MdsDatePicker from '@mds/date-picker';
import { filter } from 'lodash';
import { epTracking } from '../../../shared/utils/engagementPlatform';

export default {
  components: {
    MdsForm,
    MdsInput,
    MdsDatePicker,
    MdsFieldset,
    MdsButton,
    MdsRequiredFieldKey,
    ClientContentModal,
    GRecaptcha
  },
  mixins: [footerModalData],
  data() {
    return {
      fields: [
        {
          id: LAST_NAME,
          label: '',
          value: store.getEligibleLastName() || '',
          errorStatus: false,
          maxlength: 60,
          fieldType: 'text'
        },
        {
          id: ENTRY_CODE,
          label: '',
          microcopyTxt: this.$contentTransposer(
            'landing.verifyForm.enrollMicrocopy'
          ),
          value: store.getEntryCode() || store.getEligibleRefId() || '',
          errorId: '',
          errorMsg: '',
          errorStatus: false,
          fieldType: 'text'
        },
        {
          id: DATE_OF_BIRTH,
          label: '',
          placeholder: this.$contentTransposer(
            'landing.verifyForm.dobPlaceholder'
          ),
          value: store.getEligibleDateOfBirth() || '',
          errorId: '',
          errorMsg: '',
          errorStatus: false,
          maxlength: 10,
          minMaxDates: {
            min: new Date(1900, 1, 1),
            max: new Date()
          },
          fieldType: 'date'
        }
      ],
      recaptchaResponse: null,
      PRIVACY_POLICY
    };
  },
  computed: {
    ...mapGetters({
      getRecaptchaV2: 'getRecaptchaV2'
    }),
    getFields() {
      const { label } = this.$contentTransposer('landing');
      const veirfyFormFields = this.fields;
      Object.keys(label).forEach((key, idx) => {
        veirfyFormFields[idx].label =
          this.fields[idx].id === key ? label[key] : '';
      });
      return veirfyFormFields;
    },
    getVersion() {
      return store.getVersion();
    },
    getHasEmptyInputFields() {
      return !this.fields.every((field) => field.value);
    },
    getProductName() {
      return this.$contentTransposer('advisorFirmInfo.productName');
    }
  },
  mounted() {
    newRelicTracking();
    if (
      window &&
      (window.location.origin.includes('-qa.') ||
        window.location.origin.includes('//localhost:'))
    ) {
      epTracking(
        store.getParticipantId(),
        PAGE_ID.VERIFY,
        sessionStorage.getItem('sessionUserId')
      );
    }
  },
  methods: {
    ...mapActions({
      initializeVueState: 'initializeVueState',
      setRecaptchaV2: 'setRecaptchaV2'
    }),
    checkIfEmpty(fieldId) {
      const currentField = this.fields.find(({ id }) => id === fieldId);
      if (currentField.errorId) {
        currentField.errorId = '';
        currentField.errorMsg = '';
        currentField.errorStatus = false;
      }
    },
    validateFields() {
      this.fields.forEach((field) => {
        field.errorId = validate(field.id, field.value);
        field.errorMsg = field.errorId
          ? getErrorMessage(field.id, field.errorId)
          : '';
        field.errorStatus = !!field.errorMsg;
      });
      const hasFormValidationError = this.fields.some((field) => field.errorId);
      this.verifyParticipantEligibility(hasFormValidationError);
    },
    async verifyParticipantEligibility(hasFormValidationError) {
      if (hasFormValidationError || this.recaptchaResponse === '') {
        return;
      }
      const eligibilityObj = {
        status: null,
        showLoader: false,
        clientId: null,
        supportNumber: null
      };
      const pageContentParams = {
        isAMA: this.getIsAMA,
        advisoryFirmId: null,
        clientId: null,
        version: null
      };

      eligibilityObj.showLoader = true;
      this.$emit('toggleFormState', eligibilityObj);

      const apiBodyData = {
        ...getEnrollmentData(this.fields),
        RecaptchaResponse: this.recaptchaResponse,
        ClientId: store.getCustomizationId(),
        RIAId: store.getAdvisoryFirmId(),
        RecaptchaV2: this.getRecaptchaV2
      };
      const responseData = await apiService.postAPIRequest(
        VERIFY_PATH,
        apiBodyData
      );

      let data = null;
      let supportNumber = null;
      this.setRecaptchaV2(false);

      if (responseData.response) {
        const errors = responseData.response.data;
        const contactMessage =
          errors &&
          errors.internalErrors &&
          filter(errors.internalErrors, [
            'RuleName',
            'ProductSupportNumber'
          ])[0];
        supportNumber = contactMessage && contactMessage.Message;

        if (errors && errors.internalErrors) {
          const recaptchaErrorV3 = filter(errors.internalErrors, [
            'RuleName',
            'InvalidRecaptchaV3'
          ]);

          const recaptchaErrorV2 = filter(errors.internalErrors, [
            'RuleName',
            'InvalidRecaptchaV2'
          ]);

          if (recaptchaErrorV3.length > 0 || recaptchaErrorV2.length > 0) {
            this.recaptchaResponse = null;
            this.setRecaptchaV2(true);
            //Retain the form value if it is recaptcha error
            store.setEligibleInformation(
              {
                ...apiBodyData
              },
              false
            );
            eligibilityObj.showLoader = false;
            eligibilityObj.status = 'showForm';
            this.$emit('toggleFormState', eligibilityObj);
            return;
          }

          //Reset the form value if it is not recaptcha error
          store.setEligibleInformation(
            {
              LastName: null,
              ProposalRefId: null,
              DOB: null
            },
            false
          );
        }
      } else {
        data = responseData.data;
      }

      if (data) {
        store.setDOB(apiBodyData.DOB);
      }

      eligibilityObj.status = 'notEligible';
      eligibilityObj.showLoader = false;
      eligibilityObj.supportNumber = supportNumber;

      if (!data) {
        this.$emit('toggleFormState', eligibilityObj);
        return;
      }

      const clientId = data.ClientId.trim();

      if (BLOCK_CLIENTS.includes(clientId)) {
        eligibilityObj.clientId = clientId;
      } else {
        store.setEligibleInformation(
          {
            ...apiBodyData,
            offerExpirationDate: data.offerExpirationDate
          },
          true
        );
        store.setDbParticipantAdvice(data);

        /// VRA validation
        const vraVersionUrlAndNonVraPlan =
          store.getVersion() === 'VRA' && store.getPlanVersion() !== 'VRA';

        const vraPlanAndNonVraVersionUrl =
          store.getVersion() !== 'VRA' && store.getPlanVersion() === 'VRA';

        if (vraVersionUrlAndNonVraPlan || vraPlanAndNonVraVersionUrl) {
          const enrollmentCode = `Enrollment Code: ${store.getEligibleRefId()}`;
          const planID = store
            .getPlanInfo()
            .map((plan) => plan.PlanID)
            .join('| ');
          const errorMessage = vraPlanAndNonVraVersionUrl
            ? `${enrollmentCode} : Plan ID ${planID} : EZ enroll accessed via Non-VRA version URL`
            : `${enrollmentCode} : Plan ID ${planID} : EZ enroll accessed via VRA version URL For NON VRA Plan`;
          const errorLogBody = {
            level: {
              name: 'error'
            },
            messages: [errorMessage]
          };
          await apiService.postAPIRequest(LOG_CLIENT_ERROR, errorLogBody);
          this.$emit('toggleFormState', eligibilityObj);
          return;
        }

        store.setSessionExpired(false);
        pageContentParams.clientId = clientId;
        sessionStorage.setItem('EnrollmentCode', apiBodyData.ProposalRefId);
        pageContentParams.advisoryFirmId = store.getAdvisoryFirmId();
        pageContentParams.version = store.getVersion();
        this.initializeVueState(pageContentParams);
        this.$store.dispatch('setProposalAdvice', data.ProposalAdvice);
        this.$store.dispatch('setIncomeProjection', data.ProposalAdvice);
        const { IsEnrolled, EnrollmentDate } = data.EnrollmentInfo;
        const path =
          IsEnrolled && EnrollmentDate ? '/alreadyEnrolled' : '/advice';

        this.$router.push({
          path: `${path}`,
          query: store.getQueryParams()
        });
      }

      this.$emit('toggleFormState', eligibilityObj);
    },
    onSubmit: async function (response) {
      this.recaptchaResponse = response;
      this.validateFields();
    },
    // execute the recaptcha widget
    executeRecaptcha() {
      this.$refs.GRecaptcha.execute();
    },
    getFieldDisableStatus(id) {
      return id === ENTRY_CODE && store.getEntryCode();
    },
    handleInputChangedEvent(value, id) {
      const dateField = this.fields.find((elem) => elem.id === id);
      if (
        validate(dateField.id, value) === undefined ||
        typeof value !== 'string'
      ) {
        dateField.value = new Date(value).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
      } else {
        if (
          dateField.value.replace(value, '') !== '/' &&
          (value.match(/^\d{2}$/) !== null ||
            value.match(/^\d{2}\/\d{2}$/) !== null)
        ) {
          dateField.value = value + '/';
        } else {
          dateField.value = value;
        }
      }
      this.checkIfEmpty(dateField.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/verify-eligibility-form.scss';
</style>
