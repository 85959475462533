export default {
  advice: {
    disclaimer: `*This information is based on the age, account balance, current fund allocation, current
      contributions, and plan type provided by your plan provider. It may also consider brokerage accounts, retirement
      loans, gender and the state where you live, if those inputs are received from your plan provider. If you have
      savings earmarked for retirement outside of your retirement plan account, such as a pension, social security
      benefits, spousal assets, or other retirement accounts, log into the Blueprint Managed Advice website by going
      to your plan provider's website so that you can provide details to further personalize your suggestions. This
      website assumes a current retirement age of 65. Holding your employer's stock can increase your portfolio
      risk. Blueprint Managed Advice's retirement income projections take into account any allocation to company
      stock, but does not provide a recommendation for or management of those holdings.`
  },
  footer: {
    disclaimers: '',
    disclaimersOfferedBy: `<p class="ee__body-text--s">©{{year}} Morningstar Investment Management LLC. Under an agreement with your retirement plan sponsor, 
      CapFinancial Partners, Inc. (“CAPTRUST”), a registered investment adviser, is responsible for creating the portfolios 
      that are available to you. As a part of the Blueprint Managed Advice service option, CAPTRUST is also responsible for 
      assigning you to a specific portfolio. The technology and methodologies used within Blueprint Managed Advice and 
      certain of the text, images, layout and design appearing in it are made available to CAPTRUST pursuant to a licensing 
      agreement with Morningstar Investment Management LLC, are the property of Morningstar Investment Management LLC, and 
      are protected by intellectual property law (including, without limitation, copyright law).  The Morningstar name and 
      logo are registered marks of Morningstar, Inc.  Morningstar Investment Management LLC and Morningstar, Inc. reserve 
      all rights in any of their intellectual property that appears on or in conjunction with Blueprint Managed Advice. 
      All other brands, names and content are the property of their respective owners. Morningstar Investment Management 
      LLC is not acting in the capacity of adviser to any CAPTRUST client and is not affiliated with CAPTRUST.</p>`,
    footerLinks: {
      advisoryAgreement: {
        title: 'CAPTRUST Advisory Agreement'
      },
      privacyPolicy: {
        url: '/Merged_docs/privacy_policy/CAPTRUST_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/CAPTRUST_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url: '/Merged_docs/relationship_summary/CAPTRUST_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url: '/Merged_docs/conversation_starters/CAPTRUST_DMP_conversation_starters_merge.pdf',
        show: false
      }
    }
  },
  modals: {
    confirmEnrollment: {
      acknowledge: `I acknowledge that I have received CAPTRUST's 
        <a href="{{firmBrochureLink}}" target="_blank">{{firmBrochureName}}</a>,
        <a href="{{relationshipSummaryLink}}" target="_blank">{{relationshipSummaryName}}</a> and
        <a href="{{advisoryAgreementLink}}" target="_blank">{{advisoryAgreementName}}</a>, and I consent
        to those terms and conditions. Upon enrollment, Blueprint Managed Advice will instruct my provider
        to make any changes to my savings rate or investments if needed.`
    }
  },
  advisorFirmInfo: {
    productName: 'Blueprint Managed Advice',
    advisorFirmName: 'CapFinancial Partners, LLC ("CAPTRUST")'
  }
};
