export default {
  advisorFirmInfo: {
    advisorFirmName: 'Cota Street Investment Management'
  },
  footer: {
    footerLinks: {
      advisoryAgreement: {
        title: 'Cota Street Investment Management Advisory Agreement'
      },
      privacyPolicy: {
        url: '/Merged_docs/privacy_policy/PENSIONMARK_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/PENSIONMARK_DMP_firm_brochure_merge.pdf'
      },
      conversationStarters: {
        url: '/Merged_docs/conversation_starters/PENSIONMARK_DMP_conversation_starters_merge.pdf'
      },
      relationshipSummary: {
        url: '/Merged_docs/relationship_summary/PENSIONMARK_DMP_relationship_summary_merge.pdf'
      }
    }
  }
};
