var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"not-eligible"},[_c('StatusCardContainer',[_c('SVGImage',{staticClass:"icon",attrs:{"path":"ee-confirmation-x-icon","name":"not-eligible-icon"}}),(_vm.isClientIdBlocked)?_c('div',{attrs:{"data-ee-test":"blocked-client-container"}},[_c('p',{staticClass:"text",attrs:{"data-ee-test":"block-participant-text"},domProps:{"innerHTML":_vm._s(
          _vm.$contentTransposer('landing.statusText.blockSGSTParticipantText')
        )}})]):_c('div',{attrs:{"data-ee-test":"error-message-container"}},[_c('h3',{staticClass:"ee__section-heading",attrs:{"data-ee-test":"error-message-header"}},[_vm._v(" "+_vm._s(_vm.$contentTransposer(`${_vm.errorTextPath}.header`))+" ")]),(_vm.supportNumber)?_c('div',{staticClass:"text",attrs:{"data-ee-test":"error-message-text"},domProps:{"innerHTML":_vm._s(
          _vm.$contentTransposer(`${_vm.errorTextPath}.textWithPhone`, {
            supportNumber: _vm.supportNumber
          })
        )}}):_c('div',{staticClass:"text",attrs:{"data-ee-test":"error-message-text"},domProps:{"innerHTML":_vm._s(_vm.$contentTransposer(`${_vm.errorTextPath}.text`))}}),_c('MdsButton',{staticClass:"button",attrs:{"size":"touch","variation":"primary","data-ee-test":"try-again-button"},on:{"click":_vm.tryAgain}},[_vm._v(" "+_vm._s(_vm.$contentTransposer('button.tryAgain'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }