export default {
  enroll: {
    accountDetails: {
      text: `We need your help to finish putting your plan into action. Sign in through the <a target='_blank'
      href="https://www.millimanbenefits.com/LogOn.aspx" class="ee-link" data-ee-test="provider-display-name">MillimanBenefits.com</a>
      website to make these changes in your account(s).`
    }
  },
  footer: {
    footerLinks: {
      advisoryAgreement: {
        url: 'https://work-rm-compliance-docs.s3.amazonaws.com/Advisory_Agreement_Milliman.pdf'
      }
    }
  }
};
